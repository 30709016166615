import $ from 'jquery';
var data = null;
$(document).ready(function() {
    $('.scrollToTop').toggle();

    //Check to see if the window is top if not then display button
    $(window).scroll(function() {
        if ($(this).scrollTop() > 300) {
            $('.scrollToTop').fadeIn();
        } else {
            $('.scrollToTop').fadeOut();
        }
    });

    //Click event to scroll to top
    $('.scrollToTop').click(function() {
        $('html, body').animate({ scrollTop: 0 }, 800);
        return false;
    });


    $("button").mouseover(function() {
        //toggle old
        $("#" + data).toggle();
        data = $(this).attr("data");
        //toggle new
        $("#" + data).toggle();
    });

});